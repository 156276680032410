/** @format */

import Translator from './Translator.js';
import moment from 'moment';
import {
    months,
    monthsShort,
    weekdays,
    weekdaysShort,
    weekdaysMin
} from './frenchLocale.js';
moment.updateLocale('fr', {
    months: months,
    monthsShort: monthsShort,
    weekdays: weekdays,
    weekdaysShort: weekdaysShort,
    weekdaysMin: weekdaysMin
});
// Set Monday as the first day of the week in English
moment.updateLocale('en', {
    week: {
        dow : 1,
    }
});

import {
    dateTranslations
} from './dateTranslations.js';
import {
    calendarLoad,
    deferredTranslations,
    deferredOnPackages
} from './deferredTranslations.js';
import {
    onEventTranslations
} from './onEventTranslations.js';
import pipeTranslations from './pipeTranslations.js';
import {
    frenchRegexTranslations,
} from './regexTranslations.js';
import {
    stringTranslations,
    priceLabelTranslations,
} from './stringTranslations.js';
import {
    hrefTranslations
} from './hrefTranslations.js';
import {
    filterEvents,
    generalEntry,
    removePastTimesButtons,
    toLower,
    reorderPrices,
    translatePackagePrices,
} from './helpers.js';
import {
    dateSelectors,
    datesToLowerOnCalendar
} from './datesToLower.js';

jQuery(function () {

    // Set times for use in the dynamic buttons.
    let today = moment().format('YYYY-MM-DD');
    let tomorrow = moment().add(1, 'days').format('YYYY-MM-DD');
    let thisWeek = moment().endOf('week').format('YYYY-MM-DD');

    // Build 'dynamic' buttons for the event list view.
    let listButtons = [];

    listButtons.push('<a href="?when=today-and-tomorrow#dynamic-links" class="btn btn-default today-and-tomorrow">Today and tomorrow</a>');
    listButtons.push('<a href="?when=this-week#dynamic-links" class="btn btn-default this-week">This week</a>');
    listButtons.push('<a href="/events#dynamic-links" class="btn btn-default default">All options</a>');

    $(".tn-event-listing__primary-views-container").before("<div id='dynamic-links' class='dynamic-links'><h2>Tickets for:</h2></div>");
    $(".dynamic-links").append(listButtons);


    // Add 'dynamic' buttons to packages page.
    let packageButtons = [];

    packageButtons.push('<a href="?when=today-and-tomorrow#dynamic-links" class="btn btn-default today-and-tomorrow">Today and tomorrow</a>');
    packageButtons.push('<a href="?when=this-week#dynamic-links" class="btn btn-default this-week">This week</a>');
    packageButtons.push('<a href="/packages#dynamic-links" class="btn btn-default default">Next 30 days</a>');

    $(".tn-package-list").before("<div id='dynamic-links' class='package-dynamic-links'><h2>Tickets for:</h2></div>");
    $(".package-dynamic-links").append(packageButtons);

    $(".tn-events-listing-page .tn-heading-sub-text").before("<button class='hide-info tn-detail-production-text-toggle-link btn btn-default btn-sm'>Show instructions</button>");
    $(".tn-package-listing-page .tn-heading-sub-text").before("<button class='hide-info tn-detail-production-text-toggle-link btn btn-default btn-sm'>Show instructions</button>");

    $('.hide-info').click(function () {
        $(this).toggleClass('collapsed');
        $('.tn-heading-sub-text').toggleClass('collapsed');
    });

    // Mobile menu toggle.
    $('.c-global-nav-toggle').click(function (e) {
        e.preventDefault();
        $('body').toggleClass('state--nav-open');
    });

    // Determine if we're on a filtered page.
    if (window.location.href.split('?').length > 1) {
        $('.tn-events-listing-page').addClass('filtered');
    }

    $('input#Checkout_TandCChecked_Checkbox').click(function () {
        $(this).closest('label').toggleClass('checked');
    });


    // Remove passed times from time pickers.
    removePastTimesButtons();

    // Traslate the package prices on element change
    translatePackagePrices(priceLabelTranslations);

    // Reorder prices list including member prices.
    reorderPrices();

    // Translations.
    const translator = new Translator();
    const body = document.querySelector('body');

    body.classList.add(translator.to());
    body.classList.replace('not-translated', 'translating');

    // Set no event message
    if (translator.to() === 'fr') {
        var message = `
            <div class="tn-event-listing-view__no-results-container">
                <div class="tn-event-listing-view__no-results-content">
                    <h2>Résultats de recherche de billets</h2>
                    <p>Les billets que vous recherchez ne sont pas disponibles.</p>
                    <p>Il est possible que l\'événement soit complet ou qu\'il n\'y ait pas de billets de ce type pour la période.</p>
                    <p>Voir <a href="/events#dynamic-links">toutes les options de billets</a>.</p>
                </div>
            </div>
        `;
    } else {
        var message = `
            <div class="tn-event-listing-view__no-results-container">
                <div class="tn-event-listing-view__no-results-content">
                    <h2>Ticket-Search Results</h2>
                    <p>The tickets that you are looking for are not available.</p>
                    <p>It is possible that the event is sold out, or that there are no tickets of that type for the time period.</p>
                    <p>See <a href="/events#dynamic-links">all ticket options</a>.</p>
                </div>
            </div>
        `;
    }

    let stringSelectors = [
        // Any Requiered text.
        '.tn-required-field',

        // Creating an account: Prefix - (None).
        '#Patron_Prefix option',

        // Guest Checkout page i.e. /cart/guest
        '#GuestContact_PrefixId option',

        // Creating an account: Country - United States.
        '#Address_CountryId option',

        // Creating an account: State - Canadian States.
        '#Address_State option',

        // Calendar tabs with each different kind of Date display.
        '.tn-event-listing__primary-views-container li',

        // Print your receipt button on the Receipt page.
        '.tn-receipt-print',

        // Toggle button
        '.hide-info',

        // Dynamic buttons
        '.dynamic-links a',
        '.package-dynamic-links a',

        // Single ticket titles
        '.package-dynamic-links h2',
        '.dynamic-links h2',

        // Please Wait loader
        '.tn-loader p',

        // Select preferences 'Your changes have been saved' message
        '.tn-heading-info',
    ];

    setTimeout(function () {

        if (!$.trim($('.tn-event-listing-view__results-container ul.list-unstyled').html())) {
            $('.tn-event-listing-view__results-container').html(message)
        }

        if (!$.trim($('.tn-package-list').html())) {
            let html = "<li>" + message + "</li>";
            $('.tn-package-list').html(html)
        }

        let tninterestlabel = $('.tn-interest-23-label');
        if (tninterestlabel.html()) {
            if (translator.to() === 'fr') {
                tninterestlabel.contents().last().replaceWith("Je désire recevoir des nouvelles concernant les événements spéciaux, les offres et ce qui se passe");
            } else {
                tninterestlabel.contents().last().replaceWith("I would like to receive news about special events, offers and what's happening");
            }
        }

        let tninterestlabelEducators = $('.tn-interest-53-label');
        if (tninterestlabelEducators.html()) {
            if (translator.to() === 'fr') {
                tninterestlabelEducators.contents().last().replaceWith("Je désire recevoir des informations sur les programmes, produits et événements destinés aux éducateurs");
            } else {
                tninterestlabelEducators.contents().last().replaceWith("I would like to receive news about programs, products and events for educators");
            }
        }

        let tninterestlabelMarketing = $('.tn-interest-54-label');
        if (tninterestlabelMarketing.html()) {
            if (translator.to() === 'fr') {
                tninterestlabelMarketing.contents().last().replaceWith("J'aimerais recevoir des nouvelles au sujet de Nature Nocturne");
            } else {
                tninterestlabelMarketing.contents().last().replaceWith("I would like to receive news about Nature Nocturne");
            }
        }
    }, 1000)

    // Call to all the initial translations.
    // Everything translated here will be checked inside the methods for the language set.
    translator
        .dates(dateTranslations)
        .deferred(deferredTranslations, deferredOnPackages, calendarLoad)
        .onEvent(onEventTranslations)
        .pipe(pipeTranslations)
        .string(stringTranslations, stringSelectors)
        .replace(priceLabelTranslations, ['.tn-flex-in-progress-package__section-contents', '.tn-flex-cart__selected-pricetypes', '.tn-ticket-selector__pricetype-name', '.tn-cart-item-detail__list-item--pricetype'])
        .href(hrefTranslations, ['.c-global-nav ul li a', '.dynamic-links a.this-week', '.package-dynamic-links a.this-week', '.dynamic-links a.today-and-tomorrow', '.package-dynamic-links a.today-and-tomorrow']);


    if (translator.to() === 'fr') {
        // Run exclusive regex translations for the french site.
        // This is mainly use for price and time formating.
        translator.regex(frenchRegexTranslations);

        // Update dates that need to be lower case.
        toLower(dateSelectors);
        toLower(datesToLowerOnCalendar);

        // Prevent receipt text going lower case.
        if (document.querySelector('.tn-order-information-line.tn-order-date')) {
            let string = document.querySelector('.tn-order-information-line.tn-order-date').innerHTML;
            document.querySelector('.tn-order-information-line.tn-order-date').innerHTML = string.charAt(0).toUpperCase() + string.slice(1);
        }

        // Countries ordered inclusing USA on french.
        let countries =
            `<option value="">Sélectionnez</option>
            <option value="231"></option>
            <option value="74">Allemagne</option>
            <option value="227">Australie</option>
            <option value="266">Autre</option>
            <option value="15">Belgique</option>
            <option selected="selected" value="32">Canada</option>
            <option value="239">Chine</option>
            <option value="207">Émirats arabes unis</option>
            <option value="183">Espagne</option>
            <option value="1">États-Unis</option>
            <option value="68">France</option>
            <option value="94">Irlande</option>
            <option value="98">Israël</option>
            <option value="99">Italie</option>
            <option value="125">Méxique</option>
            <option value="147">Nigéria</option>
            <option value="146">Norvège</option>
            <option value="149">Nouvelle-Zélande</option>
            <option value="153">Pakistan</option>
            <option value="148">Pays-Bas</option>
            <option value="158">Philippines</option>
            <option value="209">Royaume-Uni</option>
            <option value="175">Singapour</option>
            <option value="192">Suisse</option>
            <option value="205">Turquie</option>`;

        // Placing those ordered countries into it select field if it exists.

        const addressElementIDs = '#Address_CountryId, #GuestBillingAddress_CountryId';
        if ($(addressElementIDs).length) {
            $(addressElementIDs).html(countries);

            // Canadian states on french ordered alphabetically.
            let states =
                `<option value=""></option>
            <option value="AB">Alberta</option>
            <option value="BC">Colombie-Britannique</option>
            <option value="PE">Île-du-Prince-Édouard</option>
            <option value="MB">Manitoba</option>
            <option value="NB">Nouveau-Brunswick</option>
            <option value="NS">Nouvelle-Écosse</option>
            <option value="NU">Nunavut</option>
            <option value="ON">Ontario</option>
            <option value="QC">Quebec</option>
            <option value="SK">Saskatchewan</option>
            <option value="NL">Terre-Neuve-et-Labrador</option>
            <option value="NT">Territoires du Nord-Ouest</option>
            <option value="YT">Yukon</option>`;

            // If the selected Country is Canada.
            if ($(addressElementIDs).val() === '32') {
                $('#Address_State, #GuestBillingAddress_State').html(states);
            }
        }

        // Listener to wait for the error message 'An unexpected error has occured.';
        function checkToReplaceUnexpectedErrorMessage() {
            setTimeout(function() {
                const englishErrorMessagesIterator = document.evaluate('//*[contains(text(), "An unexpected error has occurred.")]', document);
                const nodesToTranslate = [];
                let errorNode = englishErrorMessagesIterator.iterateNext();

                while (errorNode) {
                    nodesToTranslate.push(errorNode);
                    errorNode = englishErrorMessagesIterator.iterateNext();
                }

                for (const nodeToTranslate of nodesToTranslate) {
                    nodeToTranslate.innerHTML = nodeToTranslate.innerHTML.replace('An unexpected error has occurred.', stringTranslations['An unexpected error has occurred.'].fr);
                };
            }, 1);
        }

        // Call it once on page load to ensure that the message is translated even if no mutations occur
        checkToReplaceUnexpectedErrorMessage();
        
        const observer = new MutationObserver(checkToReplaceUnexpectedErrorMessage);
        observer.observe(document.body, { childList: true, subtree: true, characterData: true });
    }

    body.classList.replace('translating', 'translated');

    /**
     * Add mutation observer to event list, so we know when list has been updated
     * then filter the events
     */
    const targetNode = document.getElementById("tn-events-list-view");
    const callback = (mutationList, observer) => {
        for (const mutation of mutationList) {
            if (mutation.type === "childList") {
                // Filter events and general entry on the packages page.
                filterEvents();
                generalEntry();
            }
        }
    };
    const observer = new MutationObserver(callback);
    if (targetNode != null) {
        observer.observe(targetNode, { attributes: false, childList: true, subtree: true });
    }
    
    // Filter events and general entry on the packages page.
    filterEvents();
    generalEntry();

    const $anchor = $(location.hash);
    // Check element exists before triggering scroll to
    if($anchor.length) {
        $('html, body').animate({
            scrollTop: $anchor.offset().top
        }, 500);
    }
})
